export default [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home.vue"),
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: () =>
      import(/* webpackChunkName: "Accounts" */ "@/views/Accounts/"),
    children: [
      {
        name: "AccountDetails",
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "AccountDetails" */ "@/views/Accounts/Details.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/dispensations",
    name: "Dispensations",
    component: () =>
      import(/* webpackChunkName: "Dispensations" */ "@/views/Dispensations/"),
    params: true,
    children: [
      {
        path: "add",
        name: "DispensationAdd",
        component: () =>
          import(
            /* webpackChunkName: "DispensationAdd" */ "@/views/Dispensations/Add.vue"
          ),
      },
      {
        path: ":id",
        name: "DispensationEdit",
        component: () => import("@/views/Dispensations/Edit.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/events",
    name: "Events",
    component: () => import(/* webpackChunkName: "Events" */ "@/views/Events/"),
    children: [
      {
        name: "EventsDetails",
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "EventsDetails" */ "@/views/Events/Details.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/groups",
    name: "Groups",
    component: () => import(/* webpackChunkName: "Groups" */ "@/views/Groups/"),
    children: [
      {
        name: "GroupDetails",
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "GroupDetails" */ "@/views/Groups/Details.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/smartlearn",
    name: "SmartLearn",
    component: () =>
      import(/* webpackChunkName: "SmartLearn" */ "@/views/SmartLearn/"),
  },
  {
    path: "/impersonation",
    name: "Impersonation",
    component: () =>
      import(/* webpackChunkName: "Impersonation" */ "@/views/Impersonation/"),
  },
  {
    path: "/automation",
    component: () => import("@/views/Automation/"),
    children: [
      {
        path: "",
        name: "AutomationJobs",
        component: () => import("@/views/Automation/Jobs.vue"),
      },
      {
        path: "changes",
        name: "AutomationChanges",
        component: () => import("@/views/Automation/Changes.vue"),
      },
      {
        path: "changelog",
        name: "AutomationChangeLog",
        component: () => import("@/views/Automation/ChangeLog.vue"),
      },
      {
        path: "tasks",
        name: "AutomationTasks",
        component: () => import("@/views/Automation/Tasks.vue"),
      },
    ],
  },
  {
    path: "/onboarding",
    component: () =>
      import(/* webpackChunkName: "OnBoarding" */ "@/views/OnBoarding/"),
    children: [
      {
        name: "OnBoardingSchoolClasses",
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "OnBoardingSchoolClasses" */ "@/views/OnBoarding/SchoolClasses.vue"
          ),
      },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    component: () =>
      import(/* webpackChunkName: "Reports" */ "@/views/Reports/"),
  },
  {
    path: "/roomreservation",
    name: "RoomReservation",
    component: () =>
      import(
        /* webpackChunkName: "RoomReservation" */ "@/views/RoomReservation/"
      ),
    children: [
      {
        path: "add/:id",
        name: "RoomReservationCopy",
        component: () =>
          import(
            /* webpackChunkName: "RoomReservationCopy" */ "@/views/RoomReservation/Copy.vue"
          ),
        props: true,
      },
      {
        path: "add",
        name: "RoomReservationAdd",
        component: () =>
          import(
            /* webpackChunkName: "RoomReservationAdd" */ "@/views/RoomReservation/Add.vue"
          ),
        props: true,
      },

      {
        path: ":id",
        name: "RoomReservationEdit",
        component: () =>
          import(
            /* webpackChunkName: "RoomReservationEdit" */ "@/views/RoomReservation/Edit.vue"
          ),
        props: true,
      },
    ],
  },
  {
    path: "/schoolclassesoverview",
    name: "SchoolClassesOverview",
    component: () =>
      import(
        /* webpackChunkName: "SchoolClassesOverview" */ "@/views/SchoolClassesOverview/"
      ),
  },
  {
    path: "/settings",
    name: "Setting",
    component: () =>
      import(/* webpackChunkName: "Setting" */ "@/views/Settings/"),
  },
  {
    path: "/specialstatus",
    name: "SpecialStatus",
    component: () =>
      import(/* webpackChunkName: "SpecialStatus" */ "@/views/SpecialStatus/"),
  },
  {
    path: "/tasks",
    name: "Tasks",
    component: () => import(/* webpackChunkName: "Tasks" */ "@/views/Tasks/"),
  },
  {
    path: "/timetable",
    name: "Timetable",
    component: () => import("@/views/Timetable/"),
    children: [
      {
        name: "TimetableImport",
        path: "import",
        component: () => import("@/views/Timetable/ImportDialog"),
      },
      {
        name: "TimetableCode",
        path: ":code",
        component: () => import("@/views/Timetable/Timetable"),
        props: true,
      },
    ],
  },

  {
    path: "/weekoverview",
    component: () =>
      import(/* webpackChunkName: "WeekOverview" */ "@/views/WeekOverview/"),
    children: [
      {
        path: "",
        name: "WeekOverview",
        redirect: "schoolclasses",
      },
      {
        path: "schoolclasses",
        name: "WeekOverviewSchoolclasses",
        component: () => import("@/views/WeekOverview/Schoolclasses"),
      },
      {
        path: "rooms",
        name: "WeekOverviewRooms",
        component: () => import("@/views/WeekOverview/Rooms"),
      },
      {
        path: "teachers",
        name: "WeekOverviewTeachers",
        component: () => import("@/views/WeekOverview/Teachers"),
      },
      {
        path: "schoolclassescalendar",
        name: "WeekOverviewSchoolclassesCalendar",
        component: () => import("@/views/WeekOverview/SchoolclassesCalendar"),
      },
    ],
  },
];
