<template>
  <div>
    <v-toolbar flat
      ><v-spacer></v-spacer
      ><SearchField v-model="search"></SearchField> ></v-toolbar
    >
    <ChangeLogTable
      :items="itemsFiltered"
      :items-per-page="-1"
      :showPerson="false"
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import SearchField from "@/components/SearchField.vue";
import DateValue from "common/components/DateValue.vue";
import ChangeLogTable from "@/views/Automation/components/ChangeLogTable.vue";

export default defineComponent({
  name: "ChangeLog",
  components: { ChangeLogTable, DateValue, SearchField },
  props: {
    id: { type: String },
  },
  data() {
    return {
      items: [],
      search: null,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items
        .filter(
          (el) =>
            !this.search ||
            el.type.description
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            (el.description &&
              el.description.toLowerCase().includes(this.search.toLowerCase()))
        )
        .sort((a, b) => {
          const value_a = a.date + a.time;
          const value_b = b.date + b.time;
          return value_b.localeCompare(value_a);
        });
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.id) {
        return;
      }
      this.items = await this.apiGet({
        resource: "automation/changelog",
        id: this.id,
      });
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
